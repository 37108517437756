import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from './css/global';
import ScrollTop from './components/Common/ScrollTop';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollTop />
      <GlobalStyle />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);