import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

export type t = {
  postId: number,
  postTitle: string,
  postImages: string,
  postUploadDate: string,
}

export type props = {
  list: Array<t>;
}

const noNewsData = () => {
  return (
    <div className="noData">
      <p>등록된 소식이 없습니다.</p>
    </div>
  )
}

const NewsItem = styled.div`
  width: 40%;
  height: 400px;
  margin: auto;
  border: 1px solid #E6EAEE;
  border-radius: 20px;
  overflow: hidden;
  
  a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .newsImgBox { 
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .newsImgBox img {
    width: 100%;
    height: 100%;
    transition: all 0.2s ease;
  }
  &:hover img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
  }

  p {
    padding-left: 20px;
    margin: 0;
  }
  .newsTitleBox {
    height: 20%;
    display: flex;
    align-items: center;
  }
  .newsTitle {
    font-size: 18px;
    font-family: NotoSansBold;
    color: black;
  }
  .newsDateBox {
    height: 10%;
    display: flex;
    align-items: top;
    justify-content: flex-end;
  }
  .newsDate {
    padding-right: 20px;
    color: #8e8e8e;
    font-family: NotoSansRegular;
  }

`

const NewsPage = ({list}: props) => {

  return (
    <div className="newsList">
      { list.length > 0 ?
      list.map((item) => {
        return (
          <NewsItem key={item.postId}>
            <Link to={"/news/read?postId=" + `${item.postId}`} className='link'>
              <div className="newsImgBox">
                <img src={item.postImages} alt="" />
              </div>
              <div className="newsTitleBox">
                <p className="newsTitle">{item.postTitle}</p>
              </div>
              <div className="newsDateBox">
                <p className="newsDate">{item.postUploadDate}</p>
              </div>
            </Link>
          </NewsItem>
        )
      }) : noNewsData()
    }
    </div>
  )
}

export default NewsPage;