import styled from "styled-components";
import {NavLink, Link} from "react-router-dom";
import {useRef, useState} from "react";
import PersonIcon from '@mui/icons-material/Person';
import MessageIcon from '@mui/icons-material/Message';
import { SvgIcon } from "@mui/material";
import { useCookies } from "react-cookie";

const AuthItems = () => {

    const [isLogin, setIsLogin] = useState(false);

    const [cookies] = useCookies();
    
    const Container = styled.div `
    display: flex;
    justify-content: flex-end;
    margin-left: 50px;
    `;

    const NavLitemContainer = styled.div `
    display: flex;
    padding-left: 12px;
    `;

    const NavItemWrapper = styled.div `
    display: inline-flex;
    align-items: end;
    cursor: pointer;
    padding-left: 12px;

    a {
        display: flex;
        justify-content: center;
        align-items: end;
        text-decoration: none;
        color: white;
        transition: all 0.2s ease;
    }
    a:hover {
        color: #3074EC;
    }

    svg {
        width: 34px;
        height: 34px;
    }
    `;

    const items = cookies.token ? [
        {
            id: "message",
            path: '/mypage/message',
            icon: MessageIcon,
        }, {
            id: 'auth',
            path: '/mypage',
            icon: PersonIcon,
        }
    ] : [
        {
            id: 'auth',
            path: '/auth',
            icon: PersonIcon,
        }
    ]

    return (
        <Container>
            <NavLitemContainer>

                {items.map((navItem) => (
                    <NavItemWrapper key={navItem.id}>
                        <NavLink to={navItem.path}>
                            <SvgIcon component={navItem.icon} />
                        </NavLink>
                    </NavItemWrapper>
                ))}
            </NavLitemContainer>
        </Container>
    );

}

export default AuthItems;