import React, {useEffect, useState} from 'react';
import axios from 'axios';
import styled from 'styled-components';

export type t = {
    postId: number,
    postTitle: string,
    postUploadDate: string,
}

export type props = {
list: Array<t>;
}

const NoticeTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    th, td {
        text-align: center;
        height: 60px;
    }
    th {
        font-family: NotoSansBold;
        font-size: 18px;
    }
    tr {
        margin: 20px 0;
        border-bottom: 2px solid #E6EAEE;
        font-family: NotoSansRegular;
    }
    .noticeItems {
        cursor: pointer;
    }
    .noticeItems:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`

export default function Notice({list}: props) {

    const noNoticeData = () => {
        return (
          <div className="noData">
            <p>등록된 공지가 없습니다.</p>
          </div>
        )
    }
  return (
    <div className="noticeList">
        {
            list.length > 0 ? 
            <NoticeTable>
                <thead>
                    <tr>
                        <th>글 번호</th>
                        <th>제목</th>
                        <th>일자</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item) => {
                        return (
                            <tr className='noticeItems' key={item.postId}>
                                <td>{item.postId}</td>
                                <td>{item.postTitle}</td>
                                <td>{item.postUploadDate}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </NoticeTable>
            : noNoticeData()
        }
        
    </div>
  )
}
