import { useState, useEffect } from "react";
import {Link} from 'react-router-dom';
import NewsItem from "./HomeNews/NewsItem";
import axios from "axios";
import { styled } from "styled-components";

export type lists = {
    postId: number,
    postTitle: string,
    postContents: string,
    postImages: string,
    postUploadDate: string,
    postIsNotice: boolean
}

const HomeNews = () => {

    const [newsList, setNewsList] = useState(Array<lists>);

    useEffect(() => {
      axios.get("https://everyminc.com/api/posts/list")
      .then((res) => setNewsList(res.data.postList))
      .catch((err) => null
    )
    }, [])

    const noNewsData = () => {
        return (
            <div className="noData">
                <p>등록된 새소식이 없습니다.</p>
            </div>
        )
    }
    

    return (
        <section className="sections homeNewsSection">
            <div className="t-box">
                <p id="homeTitle">News</p>
                <div className="othersBox">
                    <Link to='/news' className='othersBtn'>더보기</Link>
                </div>
            </div>
            {
                newsList.length > 0 ?
                <div className="newsList">
                    {newsList.map((item) => {
                        if(!item.postIsNotice) {
                            return (
                                <NewsItem img={item.postImages} title={item.postTitle} date={item.postUploadDate}/>
                            );
                        }
                    })}
                </div>
                : noNewsData()
            }
        </section>
    )

}

export default HomeNews;