import styled from "styled-components";
import NavItems from "./NavItems";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import AuthItems from "./AuthItems";

const HeaderContainer = styled.nav`
    display: flex;
    height: 80px;
    width: 1440px;
    justify-content: center;
    align-items: center;

    background-color: #202124;

    border: none;
    position: fixed;
    top: 0;

    z-index: 999;
`;

const HeaderContentsWrapper = styled.div`
    display: flex;
    align-items: center;

    padding: 0 200px;
    width: 100%;
    max-width: 1440px;

    a {
        text-decoration: none;
    }
`;

const LogoWrapper = styled(NavLink)`
    display: inline-flex;
    align-items: center;
    width: 200px;

    img {
        width: 200px;
    }
`;

const FakeHeader = styled.div`
    height: 80px;
`;

const Header = () => {
    return (
        <>
            <HeaderContainer>
                <HeaderContentsWrapper>
                    <LogoWrapper to="/">
                        <img src={process.env.PUBLIC_URL + '/imgs/01_Main_Home/Logo.png'} alt="hello world 로고" />
                    </LogoWrapper>
                    <NavItems />
                    <AuthItems />
                </HeaderContentsWrapper>
            </HeaderContainer>
            <FakeHeader />
        </>
    );
};

export default Header;