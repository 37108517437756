import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import MyInfo from '../components/MyPage/MyInfo'
import Message from '../components/MyPage/Message'
import Menu from '../components/MyPage/Menu'
import { styled } from 'styled-components'
import Project from '../components/MyPage/Project'
import axios from 'axios'

const MyPageBox = styled.div`
    width: 80%;
    height: 620px;
    margin: auto;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
`

const ViewSection = styled.div`
    width: 850px;
    height: 100%;
    display: flex;
    border: 1px solid #E6EAEE;
    border-radius: 20px;
    background-color: white;
    font-family: NotoSansRegular;
    justify-content: center;
    align-items: center;
`

export type lists = {
    projectId: number,
    projectTitle: string,
    projectUploadDate: string,
    projectExpertName: string,
    projectState: number
}

export default function MyPage() {

    const [param, setParam] = useState('');

    const location = useLocation();

    useEffect(() => {
        let arr = location.pathname.split("/");
        let i = arr.indexOf("mypage");

        let p = i == arr.length-1 ? arr[i] : arr[i+1];
        setParam(p);
        
    },[location])

    const [projectList, setProjectList] = useState(Array<lists>);

    useEffect(() => {
        axios.get("https://everyminc.com/api/projects/list")
        .then((res) => setProjectList(res.data.projectList))
        .catch((err) => console.log(err))
    }, []);

    const viewSection = () => {

        if(param === 'project') {
            return (<Project list={projectList} />);
            return;
        } else if(param === 'message') {
            return (<Message />);
        }
        return (<MyInfo />);
    }

  return (
    <main className="main myPage">
        <MyPageBox>
            <Menu />
            <ViewSection>
                {viewSection()}
            </ViewSection>
        </MyPageBox>
    </main>
  )
}
