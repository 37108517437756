import { useState, useEffect } from "react";
import NewsPage from "../../components/News/NewsPage";
import Notice from "../../components/News/Notice";
import axios from "axios";
import styled from "styled-components";

export type lists = {
    postId: number,
    postTitle: string,
    postContents: string,
    postImages: string,
    postUploadDate: string,
    postIsNotice: boolean
}

const MenuContainer = styled.div`
    display: flex;
    width: 85%;
    margin: auto;
    height: 150px;
    align-items: end;
    justify-content: flex-start;
    font-family: NotoSansBold;

    div {
        padding: 10px 30px;
        border-radius: 30px;
        border: 3px solid #E6EAEE;
        margin: 0 20px;
        color: #6e6e6e;
        transition: all 0.2s ease;
        cursor: pointer;
    }
    div:hover {
        background-color: #2F75EC;
        color: white;
    }
    .focused {
        background-color: #2F75EC !important;
        color: white !important;
    }
`

const TabContainer = styled.div`
    width: 100%;
    min-height: 250px;

    .noData {
        width: 100%;
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: NotoSansBold;
        color: #6e6e6e;
        font-size: 20px;
    }

    .newsList {
        width: 90%;
        min-height: 500px;
        max-height: 768px;
        margin: auto;
        display: flex;
        justify-content: space-around;
        flex-flow: row wrap;
    }
    .noticeList {
        width: 90%;
        min-height: 500px;
        max-height: 768px;
        margin: auto;
        margin-top: 20px;
    }
`

export default function News() {

    const [currentTab, setCurrentTab] = useState(0);
    const [allList, setAllList] = useState(Array<lists>);

    const [newsList, setNewsList] = useState(Array<lists>);
    const [noticeList, setNoticeList] = useState(Array<lists>);

    useEffect(() => {

        axios.get("https://everyminc.com/api/posts/list")
        .then((res) => setAllList(res.data.postList))
        .catch((err) => console.log(err))
    }, [])


    useEffect(() => {

        allList.map((listItem) => {
            if(!listItem.postIsNotice) {
                if(newsList.length == 0) {
                    setNewsList([listItem]);
                } else {
                    if (newsList[newsList.length-1].postId !== listItem.postId) {
                        setNewsList([...newsList, listItem]);
                    }
                }
            } else {
                if(noticeList.length == 0) {
                    setNoticeList([listItem]);
                } else {
                    if (noticeList[noticeList.length-1].postId !== listItem.postId) {
                        setNoticeList([...noticeList, listItem]);
                    }
                }
            }
        })
    }, [allList])

    const onMenuClick = (num: number) => {
        setCurrentTab(num);
    }

  return (
    <main className="main">
        <div className="main-container">
            <MenuContainer>
                <div className={currentTab === 0 ? "menuItems focused" : "menuItems"} onClick={() => onMenuClick(0)}><span>새소식</span></div>
                <div className={currentTab === 1 ? "menuItems focused" : "menuItems"} onClick={() => onMenuClick(1)}><span>공지사항</span></div>
            </MenuContainer>
            <TabContainer>
                {currentTab == 1 ? <Notice list={noticeList} /> : <NewsPage list={newsList} />}
            </TabContainer>
        </div>
    </main>
  )
}
