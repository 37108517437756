import HomeMain from "../components/Home/HomeMain"
import HomeNews from "../components/Home/HomeNews"
// import HomePartners from "../components/Home/HomePartners";
// import HomeSupporters from "../components/Home/HomeSupporters";

const Main = () => {
    return (
        <main className="main">
            <HomeMain />
            {/* <HomePartners /> */}
            <HomeNews />
            {/* <HomeSupporters /> */}
        </main>
    )
}

export default Main;