import { styled } from "styled-components"

const ProjectSection = styled.div`
  width: 100%;
  height: 80%;
  padding: 20px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
  th, td {
    text-align: center;
    height: 60px;
  }
  tr {
    margin: 20px 0;
    border-bottom: 2px solid #E6EAEE;
  }

  .imgBox {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imgBox img {
    height: 100%;
  }
  .descNoData {
    width: 100%;
    text-align: center;
    margin-top: 0;
    color: #757B8C;
  }

`

const StateIng = styled.div`
  width: 60%;
  padding: 5px;
  margin: auto;
  background-color: #FFB54533;
  border-radius: 5px;
  color: #FFB545;
`

const StateApply = styled.div`
  width: 60%;
  padding: 5px;
  margin: auto;
  background-color: #30E0A133;
  border-radius: 5px;
  color: #30E0A1;
`

const StateComplete = styled.div`
  width: 60%;
  padding: 5px;
  margin: auto;
  background-color: #246CF933;
  border-radius: 5px;
  color: #24AFF9;
`

export type t = {
  projectId: number,
  projectTitle: string,
  projectUploadDate: string,
  projectExpertName: string,
  projectState: number
}

export type props = {
  list: Array<t>;
}

export default function Project({list}: props) {

  const returnState = (item: t) => {
    switch (item.projectState) {
      case 1:
        return (
          <StateIng>
            <span>진행중</span>
          </StateIng>
        );
        break;
      
      case 2:
        return (
          <StateComplete>
            <span>완료</span>
          </StateComplete>
        );
        break;

      default:
        return (
          <StateApply>
            <span>신청</span>
          </StateApply>
        );
        break;
    }
  }

  const noProjects = () => {
    return (
      <tr>
        <td className="noData" colSpan={4}>
          <div className="imgBox">
            <img src={process.env.PUBLIC_URL + "/imgs/07_MyPage/Img_NoData.png"} alt="프로젝트 없음" />
          </div>
          <div className="descBox">
            <p className="descNoData">등록된 프로젝트가 없습니다.</p>
          </div>
        </td>
      </tr>
    )
  }

  return (
    <ProjectSection>
      <table className="projectTable">
        <thead>
          <tr>
            <th>프로젝트 명</th>
            <th>컨설팅 신청 날짜</th>
            <th>담당 전문가</th>
            <th>진행 상태</th>
          </tr>
        </thead>
        <tbody>
          { list.length > 0 ? 
          list.map((item) => {
            return (
              <tr key={item.projectId} className="projectItems">
                <td>{item.projectTitle}</td>
                <td>{item.projectUploadDate}</td>
                <td>
                  {item.projectExpertName ? item.projectExpertName : "배정 예정"}
                </td>
                <td>
                  {returnState(item)}
                </td>
              </tr>
            )
          }) : noProjects()
        }
        </tbody>
      </table>
    </ProjectSection>
  )
}
