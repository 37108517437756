import axios from 'axios';
import https from 'https';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

const agent = new https.Agent({
    rejectUnauthorized: false
});

export const signInApi = async (data: any) => {

    const response = await axios.post("https://everyminc.com/api/auth/signIn", data).catch((err) => null);
    if(!response) return null;

    const result = response.data;
    return result;

}

export const duplicateApi = async (data: any) => {
    const response = await axios.post("https://everyminc.com/api/auth/memberUid/exists", data, {httpsAgent: agent}).catch((err) => null);
    if(!response) return null;

    const result = response.data;
    return result;
}

export const signUpApi = async (data: any) => {

    const response = await axios.post("https://everyminc.com/api/auth/signUp", data, {httpsAgent: agent}).catch((err) => null);
    if(!response) return null;

    const result = response.data;
    return result;

}