import React from 'react'
import { styled } from 'styled-components'
import { NavLink } from "react-router-dom";

const FooterBox = styled.div`
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: flex-end;
    border-top: 2px solid #dddddd;

    > div {
        display: flex;
        flex-direction: column;
        height: 80%;
        margin: auto 30px;
    }
    .callService {
        margin-right: 50px;
    }
    ul {
        list-style: none;
        padding: 0;
    }
    ul > li {
        margin: 20px 0;
        font-family: NotosansRegular;
        font-size: 18px;
    }

    a {
        color: black;
        transition: all 0.2s ease;
    }
    a:hover {
        color: #2F75EC;
    }

    .title {
        font-family: NotoSansBold;
        font-size: 28px;
        margin: 15px 0;
    }
    .callServiceDesc {
        font-family: NotoSansRegular;
        font-size: 18px;
    }
`

export default function Footer() {
  return (
    <FooterBox>
        <div className="support">
            <p className="supportTitle title">지원</p>
            <ul className='supportList lists'>
                <li className='supportListItem'>
                    <NavLink to={"/news"}>공지사항</NavLink>
                </li>
                <li className='supportListItem'>개인정보처리방침</li>
            </ul>
        </div>
        <div className="callService">
            <p className="callServiceTitle title">고객문의</p>
            <p className="callServicePhone title">everyminc@gmail.com</p>
            <div className="callServiceDescBox">
                <p className="callServiceDesc">10:00~18:00</p>
                <p className="callServiceDesc">(점심시간 13:00~14:00)</p>
                <p className="callServiceDesc">주말, 공휴일 휴무</p>
            </div>
        </div>
    </FooterBox>
  )
}
