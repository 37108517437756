import { styled } from "styled-components";

export type props = {
    img: string;
    title: string;
    date: string;
}

const HomeNewsItem = styled.div`
    width: 100%;
    cursor: pointer;

    table {
        width: 100%;
        height: 250px;
        border-bottom: 2px solid #DEDEDE;
    }

    img {
        width: 100%;
        height: 100%;
    }

    .homeNewsItemT {
        padding-left: 20px;
        font-size: 30px;
    }
    p {
        margin: 0;
    }
    .date {
        height: 50%;
        padding-left: 20px;
        color: #4D4D60;
        font-size: 20px;
    }
    .date p {
        padding-top: 20px;
    }

    span {
        font-size: 20px;
        font-weight: 800;
        color: #8E8E8E;
        transition: all 0.2s ease;
    }

    &:hover span {
        color: #2F75EC;
        padding-left: 10px;
    }
`

const NewsItem = ({img, title, date}: props): JSX.Element => {

    return (
        <HomeNewsItem>
            <table>
                <tbody>
                    <tr>
                        <td rowSpan={2} width={'20%'}>
                            <img src={process.env.PUBLIC_URL + "/imgs/01_Main_Home/Img_Background.png"} alt="img" />
                        </td>
                        <td width={'75%'}>
                            <div className="homeNewsItemT">
                                <p className="t-text">title</p>
                            </div>
                        </td>
                        <td rowSpan={2} width={'5%'}>
                            <div className="icon-area">
                                <div className="icon">
                                    <span>&gt;</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="date">
                                <p className="t-date">date</p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </HomeNewsItem>
    );

}

export default NewsItem;