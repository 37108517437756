import axios from "axios";

export const createConsultingApi = async (data: any, file: any) => {

    const formData = new FormData();
    formData.append('files', file);
    formData.append('dto', new Blob([JSON.stringify(data)], {type: "application/json"}));

    const response = await axios.post(
        "https://everyminc.com/api/projects/apply",
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).catch((err) => {
        console.log(err);
        
        return null
    });
    if(!response) return null;

    const result = response.data;
    return result;
}