import { useEffect, useState } from 'react';
import { createCsApi } from '../apis/Contact';
import { TextField } from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import InputLabel, { inputLabelClasses } from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { useStore } from '../stores';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const TitleBox = styled.div`
    width: 80%;
    color: white;
    margin: auto;

    .title {
        font-family: NotoSansBlack;
        width: 100%;
        font-size: 35px;
        margin: 0;
        padding-top: 60px;
    }
    .c-titleBox {
        padding-top: 20px;
        margin-bottom: 50px;
    }
    .contents {
        margin: 0 0 5px 0;
        font-family: NotoSansRegular;
        font-size: 16px;
    }
`

const FormBox = styled.div`
    position: relative;
    display: flex;
    width: 80%;
    margin: auto;

    input {
        background-color: white;
        border-radius: 3px;
        height: 30px;
        padding-left: 10px;
    }

    label {
        z-index: 99;
        color: #2F75EC;
        line-height: 2em;
        padding-left: 10px;
        font-family: NotoSansRegular;
    }
    label.Mui-focused {
        transform: translate(0, -10px) scale(0.75) !important;
        color: white;
    }
    label.MuiFormLabel-filled {
        transform: translate(0, -10px) scale(0.75) !important;
        color: white;
    }
`

const InputBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    > div {
        width: 45%;
    }
    .right label {
        color: white;
    }
`

const ButtonBox = styled.div`
    position: absolute;
    right: 0;
    bottom: -50px;
`

const Contact = () => {

    const [csTitle, setCsTitle] = useState('');
    const [csCompany, setCompany] = useState('');
    const [csName, setName] = useState('');
    const [csEmail, setEmail] = useState('');
    const [csPhone, setPhone] = useState('');
    const [csContents, setContents] = useState('');
    const [csState, setState] = useState(false);

    const {member} = useStore();

    const navigate = useNavigate();

    useEffect(() => {
        if(member) {
            setName(member.memberName);
            setEmail(member.memberEmail);
            setPhone(member.memberPhone);
            setCompany(member.memberCompany);
        }
    }, [])

    const createCs = async () => {

        if(csTitle.length < 1) {
            alert('제목을 입력해주세요.');
            return;
        }

        if(csName.length < 1) {
            alert('이름을 입력해주세요.');
            return;
        }
        if(csEmail.length < 1) {
            alert('이메일을 입력해주세요.');
            return;
        }
        if(csPhone.length < 1) {
            alert('연락처를 입력해주세요.');
            return;
        }
        if(csContents.length < 1) {
            alert('내용을 입력해주세요.');
            return;
        }

        const data = {
            csTitle,
            csContents,
            csCompany,
            csName,
            csEmail,
            csPhone,
            csUploadDate: new Intl.DateTimeFormat('kr').format(new Date()),
            csState,
        }

        const createCsResponse = await createCsApi(data);

        if(!createCsResponse) {
            alert('작성 내용을 확인해주세요.');
            return;
        } else {
            alert("문의사항이 접수되었습니다.");
            navigate("/");
            return;
        }
        if(!createCsResponse.result) {
            alert('작성 내용을 확인해주세요.');
            return;
        } else {
            alert("문의사항이 접수되었습니다.");
            navigate("/");
            return;
        }

    }

  return (
    <main className='main'>

        <section className='sections contactSection'>
            <div className="bg">
                <img src={process.env.PUBLIC_URL + "/imgs/06_Contact/Img_Background.png"} alt="문의 bg" />
            </div>
            <TitleBox>
                <div className="t-titleBox">
                    <p className="title">문의하기</p>
                </div>
                <div className="c-titleBox">
                    <p className="contents">에브림 서비스 관련 문의나 궁금하신 점이 있다면 아래 양식을 적어주세요.</p>
                    <p className="contents">최대한 빠른 시일 내에 답변 드리겠습니다.</p>
                </div>
            </TitleBox>
            <FormBox>
                <FormControl sx={{m: 1, width:'auto', margin: 0}} variant="standard">
                    <TextField
                        id='csTitle'
                        sx={
                            {
                                width:'1152px', marginBottom:'20px'
                            }
                        }
                        label='제목' 
                        variant="standard"
                        name="csTitle"
                        onChange={(e) => setCsTitle(e.currentTarget.value)}
                        required
                    />
                    <InputBox>
                        <div className="left">
                            <TextField 
                                id='csCompany' 
                                label='회사명' 
                                sx={
                                    {
                                        width:'100%', marginBottom:'20px'
                                    }
                                }
                                variant="standard" 
                                name="csCompany" 
                                onChange={(e) => setCompany(e.currentTarget.value)}
                                defaultValue={member ? member.memberCompany : ""} />
                            <TextField 
                                id='csName' 
                                label='이름'
                                sx={
                                    {
                                        width:'100%', marginBottom:'20px'
                                    }
                                }
                                variant="standard" 
                                name="csName" 
                                onChange={(e) => setName(e.currentTarget.value)} 
                                required 
                                defaultValue={member ? member.memberName : ""} />
                            <TextField 
                                id='csEmail' 
                                label='이메일'
                                sx={
                                    {
                                        width:'100%', marginBottom:'20px'
                                    }
                                }
                                variant="standard" 
                                name="csEmail" 
                                onChange={(e) => setEmail(e.currentTarget.value)} 
                                required 
                                defaultValue={member ? member.memberEmail : ""} />
                            <TextField 
                                id='csPhone' 
                                label='연락처'
                                sx={
                                    {
                                        width:'100%', marginBottom:'20px'
                                    }
                                }
                                variant="standard" 
                                name="csPhone" 
                                onChange={(e) => setPhone(e.currentTarget.value)} 
                                required 
                                defaultValue={member ? member.memberPhone : ""} />
                        </div>
                        <div className="right">
                            <label htmlFor="csContents">내용*</label>
                            <Textarea 
                                id='csContents' 
                                name="csContents"
                                placeholder='문의사항을 입력해주세요.'
                                onChange={(e) => setContents(e.currentTarget.value)} 
                                minRows={10}
                                maxRows={10}
                                required />
                        </div>
                    </InputBox>
                </FormControl>
                <ButtonBox>
                    <Button fullWidth onClick={() => createCs()} variant="contained">제출</Button>
                </ButtonBox>
            </FormBox>
        </section>

    </main>
  )
}


export default Contact;