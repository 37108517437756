import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { useRef, useState } from "react";
import { useCookies } from "react-cookie";

const Container = styled.div`
    flex: 1 0 0%;
    display: flex;
    justify-content: flex-end;
`;

const NavLitemContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    a {
        text-decoration: none;
        color: white;
        transition: all 0.2s ease;
    }

    a:hover {
        color: #3074EC;
    }
`;

const NavItemWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    font-family: NotoSansRegular;
    font-size: 17px;
    letter-spacing: 1px;

    & + & {
        margin-left: 30px;
    }

    a {
        opacity: 0.8;
        padding: 8px 18px;
        border-radius: 20px;
        transition: all 0.2s ease;
        cursor: pointer;
    }
    &:hover a {
        color: #3074EC;
        opacity: 1;
    }
    &:last-child a {
        background-color: #fff;
        text-align: center;
        font-weight: bold;
        color: #3074EC;
        opacity: 1;
        transition: all 0.2s ease;
    }
    &:last-child:hover a {
        background-color: #3074EC;
        color: #fff;
    }
`;

const NavItems = () => {
    const [isSubnavModalOn, setIsSubnavMoalOn] = useState(false);

    const [cookies] = useCookies();

    const navContainerRef = useRef<HTMLDivElement | null>(null);
    const subModalControllerRef = useRef<HTMLDivElement | null>(null);

    const navItems = 
        [
            {
                id: "Service",
                path: "/service",
            },
            {
                id: "News",
                path: "/news",
            },
            {
                id: "Contact",
                path: "/contact",
            },
            {
                id: "Smart Consulting",
                path: cookies.token ? "/consulting" : "/auth",
            },
        ];

    return (
        <Container>
            <NavLitemContainer>
                {navItems.map((navItem) => (
                    <NavItemWrapper key={navItem.id}>
                        <NavLink to={navItem.path}>
                            {navItem.id}
                        </NavLink>
                    </NavItemWrapper>
                ))}
            </NavLitemContainer>
        </Container>
    );
};

export default NavItems;