import styled from "styled-components";

const InfoDivs = styled.div`
    width: 100%;
    height: 500px;
    display: flex;
    justify-content:center;

    img {
        height: 350px;
    }

    div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .left {
        width: 40%;
    }
    .right {
        width: 50%;
    }

    .info {
        width: 100%;
        font-family: NotoSansBold;
        font-size: 45px;
        margin: 0;
        text-align: left;
    }
    .left .info {
        padding-left: 100px;
    }
`

const RectangleBox = styled.div`
    position: absolute;
    width: 100%;
    height: 700px;
    top: 400px;
    left: 0;
    text-align: right;
    z-index: -9;

    img {
        width: 850px;
        height: 100%;
        z-index: -9;
    }
`

const Information = () => {

    return (
        <section className="sections">
            <RectangleBox>
                <img src={process.env.PUBLIC_URL + "/imgs/04_About/Img_BackgroundBox_.png"} alt="" id="rectangle" />
            </RectangleBox>
            <InfoDivs>
                <div className="left">
                    <img src={process.env.PUBLIC_URL + "/imgs/04_About/Img_Intro1_.png"} alt="소개 이미지" />
                </div>
                <div className="right">
                    <p className="info">최소 20년 이상의</p>
                    <p className="info">고경력 양산 전문가들과</p>
                    <p className="info">함께하고 있습니다</p>
                </div>
            </InfoDivs>
            <InfoDivs>
                <div className="left">
                    <p className="info">간단한 정보 입력으로,</p>
                    <p className="info">최적의 양산 전문가들과</p>
                    <p className="info">매칭시켜 드립니다</p>
                </div>
                <div className="right">
                    <img src={process.env.PUBLIC_URL + "/imgs/04_About/Img_Intro2_.png"} alt="소개 이미지" />
                </div>
            </InfoDivs>
            <InfoDivs>
                <div className="left">
                    <img src={process.env.PUBLIC_URL + "/imgs/04_About/Img_Intro3_.png"} alt="소개 이미지" />
                </div>
                <div className="right">
                    <p className="info">전문가와</p>
                    <p className="info">직접 소통할 수 있는</p>
                    <p className="info">경제적인 솔루션</p>
                </div>
            </InfoDivs>
        </section>
    );

}

export default Information;