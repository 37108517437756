import { Button } from '@mui/material';
import React, { useEffect, useState, useRef, useCallback, ChangeEvent } from 'react'
import { useStore } from '../stores';
import { TextField } from "@mui/material";

import '../css/DragDrop.css';
import { createConsultingApi } from '../apis/Consulting';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface IFileType {
  id:number;
  object:File;
}

const TitleSection = styled.div`
  position: relative;
  width: 100%;
  height: 400px;

  .title {
    font-family: NotoSansBlack;
    font-size: 50px;
    width: 100%;
    text-align: center;
    margin-top: 0;
    padding-top: 70px;
  }
  .desc {
    font-family: NotoSansBold;
    font-size: 20px;
    width: 100%;
    text-align: center;
  }
`

const StepBox = styled.div`
  width: 80%;
  height: 180px;
  padding-top: 30px;
  display: flex;
  justify-content: space-around;
  margin: auto;
  
  img {
    height: 80px;
  }

  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  label {
    font-family: NotoSansRegular;
    padding-top: 10px;
  }
`

const ChoiceOuterBox = styled.div`
  width: 80%;
  height: 600px;
  display: flex;
  margin: auto;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 16px 60px #0000000F;
  border-radius: 15px;
  
  label {
    width: 40%;
    border-radius: 8px;
    border: none;
  }

  img {
    width: 100%;
  }

  .choiceBox {
    height: 460px;
    border-radius: 15px;
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.2s ease;
  }
  .choiceBox:hover {
    opacity: 1;
  }
  .checked {
    opacity: 1;
    border: 5px solid #005EFF7E;
  }

  .choice-title-box {
    padding: 15px;
    padding-bottom: 0;
  }
  .choice-desc-box {
    padding: 15px;
    padding-top: 0;
  }
  .choice-title {
    font-family: NotoSansBold;
    font-size: 20px;
    margin-top: 0;
  }
  .choice-desc {
    font-family: NotoSansRegular;
    font-size: 16px;
    margin: 0;
  }
`

const TopBox = styled.div`
  height: 300px;
  display: flex;
  padding: 50px;
  margin: auto;

  .basicInfoBox {
    width: 65%;
  }

  .infoTitle, .fileUploadTitle {
    font-family: NotoSansBold;
    font-size: 20px;
    padding-left: 40px;
    width: 100%;
  }
  .fileUploadTitle {
    padding-left: 50px;
  }

  .basicInfo {
    display: flex;
    width: 100%;
    height: 220px;
    padding-left: 40px;
    margin-top: 20px;
  }
  .infoInputs {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
  .infoInputs > div {
    margin-bottom: 40px;
  }
  .infoInputs input {
    border-radius: 10px;
    height: 17px;
    padding-left: 10px;
  }
  label span {
    color: red;
  }
  
  .fileUploadArea {
    text-align: center;
  }
  .fileUploadArea img {
    width: 50%;
  }
  .fileUploadArea p {
    margin-bottom: 0;
  }
  .fileName {
    margin: 0;
  }
  .warning {
    color: gray;
    font-family: NotoSansRegular;
    font-size: 12px;
  }
`

const ItemDescBox = styled.div`
  width: 85%;
  height: 300px;
  margin: auto;
  
  label span {
    color: red;
  }

  > div:last-child {
    margin-top: 20px;
  }
`

const EndTitleBox = styled.div`
  height: 300px;
  text-align: center;

  > div {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    height: 70%;
  }
  
  .endTitle {
    font-family: NotoSansBold;
    font-size: 40px;
    margin: 15px;
  }
`

const EndDescBox = styled.div`
  height: 100px;
  text-align: center;
  font-family: NotoSansRegular;
  font-size: 17px;
`

const EndAccountBox = styled.div`
  height: 100px;
  text-align: center;
  
  .endAccount {
    font-family: NotoSansBold;
    font-size: 22px;
    margin: 5px;
  }
  .endAccDesc {
    font-family: NotoSansRegular;
    color: red;
    margin: 0;
  }
`

export default function Consulting() {

  const navigate = useNavigate();

    const [consultingStep, setConsultingStep] = useState(0);

    const {member} = useStore();

    const [consultingType, setConsultingType] = useState<number>();
    const [name, setName] = useState<string>(member !== null ? member.memberName : "");
    const [company, setCompany] = useState<string>(member !== null ? member.memberCompany : "");
    const [phone, setPhone] = useState<string>(member !== null ? member.memberPhone : "");
    const [email, setEmail] = useState<string>(member !== null ? member.memberEmail : "");

    const [itemFile, setItemFile] = useState<IFileType[]>([]);
    const ALLOW_FILE_EXTENSION = 'zip';

    // 드래그 중일 때, 아닐 때 구분 -> css
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [isAlerted, setIsAlerted] = useState<boolean>(false);
    // 선택 file 고유 id
    const fileId = useRef<number>(0);
    // 드래그 이벤트 감지 ref 참조 변수(label 태그에 들어감)
    const dragRef = useRef<HTMLLabelElement | null>(null);
    const [itemDesc, setItemDesc] = useState<string>('');
    const [question, setQuestion] = useState<string | null>(null);
    const [consultingState, setConsultingState] = useState(0);

    const [secondPageClear, setSecondPageClear] = useState(false);

    const titles = [
      "스마트 컨설팅 서비스",
      "페이퍼 컨설팅 신청",
      "프리미엄 컨설팅 신청"
    ];
    const descs = [
      "원하시는 서비스를 선택해주세요.",
      "페이퍼 컨설팅은 저렴한 가격에 도면을 바탕으로 '온라인 서면 피드백'을 제공하는 서비스입니다.",
      "프리미엄 컨설팅은 합리적인 가격으로 실제 금형에 적합한 설계가 될 수 있도록, 전문가가 1:1로 멘토링을 하며 진행되는 서비스입니다."
    ];

    const consultingStepController = (num: number) => {
      if(consultingStep > num) {
        setItemFile([]);
        setItemDesc('');
        setQuestion('');
        setSecondPageClear(false);
        setConsultingStep(num);
      } else {
        setConsultingStep(num);
      }
    }

    const onChangeFiles = useCallback((e: ChangeEvent<HTMLInputElement> | any): void => {
      let selectFiles: File[] = [];
      let tempFiles: IFileType[] = itemFile;

      if(e.type === "drop") {
        selectFiles = e.dataTransfer.files;
      } else {
        selectFiles = e.target.files;
      }

      for(const file of selectFiles) {

        if(!fileExtensionValid(file)) {
          alert(`.zip파일만 업로드 가능합니다.`);
          setIsAlerted(true);
          return;
        }
        setIsAlerted(false);

        tempFiles = [
          ...tempFiles,
          {
            id:fileId.current++,
            object: file
          }
        ];
      }

      setItemFile(tempFiles);
    }, [itemFile]);

    const fileExtensionValid = ({name} : {name:string}) => {
      const extension = removeFileName(name);

      if(!(ALLOW_FILE_EXTENSION.indexOf(extension) > -1) || extension === '') {
        return false;
      }

      return true;
    }

    const removeFileName = (originalFileName:string) => {
      const lastIndex = originalFileName.lastIndexOf(".");
      if(lastIndex < 0) return "";

      return originalFileName.substring(lastIndex+1).toLowerCase();
    }

    const showTitle = () => {
      if(consultingStep == 0) {
        return titles[0];
      } else {
        if(consultingType == 0) return titles[1];
        else return titles[2];
      }
    }

    const showDesc = () => {
      if(consultingStep == 0) {
        return descs[0];
      } else {
        if(consultingType == 0) return descs[1];
        else return descs[2];
      }
    }

    const handleDragIn = useCallback((e: DragEvent): void => {
      e.preventDefault();
      e.stopPropagation();
    }, []);

    const handleDragOut = useCallback((e: DragEvent): void => {
      e.preventDefault();
      e.stopPropagation();

      setIsDragging(false);
    }, []);

    const handleDragOver = useCallback((e: DragEvent): void => {
      e.preventDefault();
      e.stopPropagation();

      if(e.dataTransfer!.files) {
        setIsDragging(true);
      }
    }, []);

    const handleDrop = useCallback(
      (e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();
        
        onChangeFiles(e);
        setIsDragging(false);
    }, [onChangeFiles]);

    const initDragEvents = useCallback((): void => {
      // 마운트 될 때, 이벤트에 listener 등록
      if(dragRef.current !== null) {
        dragRef.current
        .addEventListener("dragenter", handleDragIn);
        dragRef.current
        .addEventListener("dragleave", handleDragOut);
        dragRef.current
        .addEventListener("dragover", handleDragOver);
        dragRef.current
        .addEventListener("drop", handleDrop);
      }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

    const resetDragEvents = useCallback((): void => {
      if(dragRef.current !== null) {
        dragRef.current
        .removeEventListener("dragenter", handleDragIn);
        dragRef.current
        .removeEventListener("dragleave", handleDragOut);
        dragRef.current
        .removeEventListener("dragover", handleDragOver);
        dragRef.current
        .removeEventListener("drop", handleDrop);
      }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop])

    useEffect(() => {
      initDragEvents()
      return () => resetDragEvents()
    }, [initDragEvents, resetDragEvents]);

    const getFileName = () => {
      if(itemFile.length > 0) {
        const {id, object: {name}} = itemFile[itemFile.length-1];
        
        return (
          <div className="fileNameBox" key={id}>
            <p className="fileName">{name}</p>
          </div>
        );
      } else {
        return (
          <div className="fileNameBox">wrong</div>
        )
      }
    }

    const imgSrcSetting = () => {
      if(isAlerted) {
        return (
          <div className="fileUploadArea">
            <img src={process.env.PUBLIC_URL + "/imgs/03_Smart_Consulting/Icn_Upload_rd.png"} alt="호환 문제" className="fileUploadImg" />
            <p className="uploadRd">호환되지 않는 형식입니다.</p>
          </div>
        )
      } else if(itemFile.length > 0) {
        return (
          <div className="fileUploadArea">
            <img src={process.env.PUBLIC_URL + "/imgs/03_Smart_Consulting/Icn_Upload_bl.png"} alt="업로드 완료" className="fileUploadImg" />
            {getFileName()}
            <p className="uploadBl">업로드 되었습니다.</p>
          </div>
        ) 
      }
      return (
        <div className="fileUploadArea">
          <img src={process.env.PUBLIC_URL + "/imgs/03_Smart_Consulting/Icn_Upload_gr.png"} alt="업로드 영역" className="fileUploadImg" />
          <p className="uploadGr">.zip 파일을 올려주세요.</p>
        </div>
      )
    }

    const applyHandler = async () => {

      if(name.length < 1) {
        alert('이름을 입력해주세요.');
        return;
      }
      if(phone.length < 1) {
        alert('휴대폰 번호를 입력해주세요.');
        return;
      }
      if(email.length < 1) {
        alert('이메일을 입력해주세요.');
        return;
      }
      if(itemFile == null) {
        alert('도면을 등록해주세요.');
        return;
      }
      if(name.length < 1) {
        alert('아이템 설명을 입력해주세요.');
        return;
      }

      const data = {
        memberId: member.memberId,
        expertId: null,
        projectType: consultingType,
        memberName: name,
        memberPhone: phone,
        memberEmail: email,
        memberCompany: company,
        projectTitle: itemFile[itemFile.length - 1].object.name,
        projectContents: itemDesc,
        projectQuestion: question,
        projectDesign: itemFile[itemFile.length - 1].object.name,
        projectState: consultingState,
        projectUploadDate: new Intl.DateTimeFormat('kr').format(new Date()),
      }

      const createConsultingResponse = await createConsultingApi(data, itemFile[itemFile.length - 1].object);

      if(!createConsultingResponse) {
        return;
      } else {
        alert('컨설팅 신청이 완료되었습니다.');
        setConsultingStep(consultingStep + 1);
        return;
      }
      if(!createConsultingResponse.result) {
        alert('작성 내용을 확인해주세요.');
        return;
      } else {
        alert('컨설팅 신청이 완료되었습니다.');
        setConsultingStep(consultingStep + 1);
        return;
      }
    }

    useEffect(() => {
      if(name.length > 0 && phone.length > 0 && email.length > 0 && itemDesc.length > 0 && itemFile.length > 0) {
        setSecondPageClear(true);
      }
      else setSecondPageClear(false);
    },[name, phone, email, itemDesc, itemFile])

    return (
      <main className="main">
        <TitleSection>
          <div className="titleBox">
            <p className="title">{showTitle()}</p>
          </div>
          <div className="descBox">
            <div className="desc">{showDesc()}</div>
          </div>
          <StepBox>
            <div className="first">
              <img src={process.env.PUBLIC_URL + "/imgs/03_Smart_Consulting/Icn_Circle_1_checked.png"} alt="step1 icn" id='first' />
              <label htmlFor="first">서비스 유형 선택</label>
            </div>
            <div className="second">
              <img src={consultingStep > 0 ? process.env.PUBLIC_URL + "/imgs/03_Smart_Consulting/Icn_Circle_2_checked.png" : process.env.PUBLIC_URL + "/imgs/03_Smart_Consulting/Icn_Circle_2_unchecked.png"} alt="step2 icn" id='second' />
              <label htmlFor="second">신청서 작성</label>
            </div>
            <div className="third">
              <img src={consultingStep > 1 ? process.env.PUBLIC_URL + "/imgs/03_Smart_Consulting/Icn_Circle_3_checked.png" : process.env.PUBLIC_URL + "/imgs/03_Smart_Consulting/Icn_Circle_3_unchecked.png"} alt="step3 icn" id='third' />
              <label htmlFor="third">신청 완료</label>
            </div>
          </StepBox>
        </TitleSection>
        <div className="sections">
          <div className={consultingStep === 0 ? "typeWrapper" : "typeWrapper hidden"}>
            <ChoiceOuterBox>
              <input type="radio" name="type" id="paper" onChange={() => setConsultingType(0)} />
              <label htmlFor="paper">
                <div className={consultingType === 0 ? "choiceBox checked" : "choiceBox"}>
                  <div className="imgBox">
                    <img src={process.env.PUBLIC_URL + "/imgs/03_Smart_Consulting/Img_Basic_Active.png"} alt="페이퍼 컨설팅 이미지" className="paperImg" />
                  </div>
                  <div className="choice-title-box">
                    <p className="choice-title">페이퍼 컨설팅</p>
                  </div>
                  <div className="choice-desc-box">
                    <p className="choice-desc">
                      저렴한 가격에 금형 가능 여부, 금형 시 발생 가능한 문제점 등
                    </p>
                    <p className="choice-desc">
                      도면을 바탕으로 서면 피드백을 제공하는 서비스입니다.
                    </p>
                  </div>
                </div>
              </label>
              <input type="radio" name="type" id="premium" onChange={() => setConsultingType(1)} />
              <label htmlFor="premium">
                <div className={consultingType === 1 ? "choiceBox checked" : "choiceBox"}>
                  <div className="imgBox">
                    <img src={process.env.PUBLIC_URL + "/imgs/03_Smart_Consulting/Img_Premiun_Active.png"} alt="프리미엄 컨설팅 이미지" className="premiumImg" />
                  </div>
                  <div className="choice-title-box">
                    <p className="choice-title">프리미엄 컨설팅</p>
                  </div>
                  <div className="choice-desc-box">
                    <p className="choice-desc">
                      프리미엄 서비스로, 합리적인 가격으로
                    </p>
                    <p className="choice-desc">
                      실제 금형에 적합한 설계가 될 수 있도록,
                    </p>
                    <p className="choice-desc">
                       전문가가 1:1로 멘토링을 하며 진행되는 서비스입니다.
                    </p>
                  </div>
                </div>
              </label>
            </ChoiceOuterBox>

            <Button className='consultNext' variant="contained" disabled={consultingType !== undefined ? false : true} onClick={() => consultingStepController(consultingStep + 1)}>다음 &gt;</Button>
          </div>

          <div className={[consultingStep === 1 ? "inputWrapper" : "inputWrapper hidden", consultingType===0 ? "" : "premiumWrapper"].join(" ")}>
            <TopBox>
              <div className="basicInfoBox">
                <div className="infoTitleBox">
                  <p className="infoTitle">기본 정보 입력하기</p>
                </div>
                <div className="basicInfo">
                  <div className="infoInputs">
                    <TextField 
                      id="name" 
                      sx={
                            {
                                width:'270px', marginBottom:'20px'
                            }
                        }
                      label="이름" 
                      variant="outlined" 
                      onChange={(e) => setName(e.currentTarget.value)} 
                      defaultValue={name} 
                      required />
                    <TextField 
                      id="companyName" 
                      sx={
                            {
                                width:'270px', marginBottom:'20px'
                            }
                        }
                      label="소속 기업" 
                      variant="outlined" 
                      onChange={(e) => setCompany(e.currentTarget.value)} 
                      defaultValue={company} />
                  </div>
                  <div className="infoInputs">
                    <TextField 
                      id="phone" 
                      sx={
                            {
                                width:'270px', marginBottom:'20px'
                            }
                        }
                      type="phone" 
                      label="휴대폰 번호" 
                      variant="outlined" 
                      onChange={(e) => setPhone(e.currentTarget.value)} 
                      defaultValue={phone} 
                      required />
                    <TextField 
                      id="email" 
                      sx={
                            {
                                width:'270px', marginBottom:'20px'
                            }
                        }
                      type="email" 
                      label="이메일" 
                      variant="outlined" 
                      onChange={(e) => setEmail(e.currentTarget.value)} 
                      defaultValue={email} 
                      required />
                  </div>
                </div>
              </div>
              <div className="fileUpload">
                <p className="fileUploadTitle">도면 업로드</p>
                <input 
                  type="file"
                  name="itemDesign"
                  id="itemDesign"
                  onChange={(event) => onChangeFiles(event)}
                  style={{display:"none"}}
                  multiple={false} />
                <label
                  htmlFor="itemDesign"
                  className={isDragging ? "DragDropFileDragging" : "DragDropFile"}
                  ref={dragRef}
                  >
                  {imgSrcSetting()}
                </label>
                <p className="warning">* 도면에 이상이 있는 경우, 메세지를 통해 다시 요청받을 수 있습니다.</p>
              </div>
            </TopBox>
            <ItemDescBox>
              <TextField
                id="itemDesc"
                name="itemDesc"
                label="아이템 설명"
                placeholder="제작하고자 하는 아이템(제품)에 대해 설명을 작성해주세요. 자세하게 기재할수록, 컨설팅의 질이 높아집니다."
                fullWidth
                multiline
                onChange={(e) => setItemDesc(e.currentTarget.value)}
                required
                rows={4}
              />
              {consultingType === 1 ? 
                  <TextField
                    id="question"
                    name="question"
                    label="궁금한 사항"
                    placeholder="추가적으로 궁금하신 사항이 있으시다면, 작성해주세요. 자세하게 기재할수록, 컨설팅의 질이 높아집니다."
                    fullWidth
                    multiline
                    onChange={(e) => setQuestion(e.currentTarget.value)}
                    rows={4}
                  /> : null}

            </ItemDescBox>
            
            <div className="btnBox">
              <Button variant="contained" id='prevBtn' onClick={() => consultingStepController(consultingStep - 1)}>&lt; 이전</Button>
              <Button variant="contained" id='applyBtn' disabled={secondPageClear ? false : true} onClick={() => applyHandler()}>신청하기</Button>
            </div>
          </div>

          <div className={consultingStep === 2 ? "endWrapper" : "endWrapper hidden"}>
            <EndTitleBox>
              <div>
                <img src={process.env.PUBLIC_URL + "/imgs/03_Smart_Consulting/Icn_Circle_Check.png"} alt="체크 이미지" className="endImg" />
              </div>
              <p className="endTitle">신청이 완료되었습니다!</p>
            </EndTitleBox>
            <EndDescBox>
              <p className="endDesc">컨설팅을 신청해주셔서 감사합니다.</p>
              <p className="endDesc">아래 계좌번호에 입금이 완료되면, 컨설팅이 진행됩니다.</p>
            </EndDescBox>
            <EndAccountBox>
              <p className="endAccount">계좌번호: 기업은행 332-126404-04-017 주식회사 에브림</p>
              <p className="endAccDesc">* 컨설팅 신청자의 이름 혹은 기업명과 입금자명이 일치해야 합니다.</p>
            </EndAccountBox>

            <Button variant="contained" onClick={() => navigate("/mypage/project")}>확인</Button>
          </div>
        </div>
      </main>
    )
}
