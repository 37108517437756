import { CSSProperties, useEffect, useState } from "react";
import * as React from 'react';

import { useNavigate } from "react-router-dom";
import { FilledInput, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { duplicateApi, signUpApi } from "../../apis/Auth";
import styled from "styled-components";

const marginTop:CSSProperties = {
    marginTop: '10px'
};

const SignUpWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`

const PolicyWrapper = styled.div`
    font-family: NotoSansRegular;
    font-size: 12px;
    
    .t-policy {
        font-family: NotoSansBold;
        padding-left: 10px;
    }
    .policy-box {
        width: 90%;
        height: 300px;
        margin: auto;
        overflow: scroll;
        padding-left: 10px;
        border-radius: 10px;
        border: 1px solid #E4E6E8;
        font-size: 11px;
    }
    .policy-box::-webkit-scrollbar {
        display: none;
    }

    dt {
        font-family: NotoSansBold;
        margin: 5px 0;
    }
    dd {
        margin: 3px 10px;
    }

    h3 {
        margin: 0;
    }

    label {
        width: 90%;
        margin: 10px auto;
        display: flex;
        justify-content: flex-end;
    }
    .agreeChkBox {
        width: 20px;
        margin-right: 5px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .agreeChkBox img {
        width: 100%;
        height: 100%;
        opacity: 0.3;
    }
    .checked img {
        opacity: 1 !important;
    }

    .btnBox {
        width: 90%;
        margin: auto;
    }
`

const IdPwWrapper = styled.div`
    width: 100%;
    height: 100%;
    font-family: NotoSansRegular;

    .t-info {
        font-family: NotoSansBold;
        padding-left: 20px;
    }

    .MuiFormControl-root {
        margin: 8px 0;
    }
    .MuiInputBase-root {
        background: none;
        border: 1px solid #E4E6E8;
    }
    .MuiInputBase-root:hover {
        background: none;
    }

    .id-area {
        width: 95%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px auto 0 auto;
    }
    .id-area .input-boxes {
        width: 80%;
    }
    .id-area button {
        height: 56px;
        font-family: NotoSansRegular;
    }
    .err-area {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .err-area p {
        padding-right: 10px;
        margin: 0;
        font-family: NotoSansRegular;
        font-size: 12px;
    }
    .success {
        color: #2F75EC;
    }
    .err {
        color: #AA4A4A;
    }

    .pw-area {
        width: 95%;
        margin: 20px auto 0 auto;
    }
    .pw-area .input-boxes {
        width: 100%;
    }
    
    .btnBox {
        width: 95%;
        margin: 40px auto 0 auto;
    }
`

const InfoWrapper = styled.div`
    width: 100%;
    height: 100%;
    font-family: NotoSansRegular;

    p {
        width: 95%;
        margin: 12px auto 5px auto;
    }

    .input-boxes {
        width: 95%;
        margin: auto;
    }
    .MuiFormControl-root {
        margin: 5px auto 0 auto;
    }
    .MuiInputBase-root {
        background: none;
        border: 1px solid #E4E6E8;
    }
    .MuiInputBase-root:hover {
        background: none;
    }

    .btnBox {
        width: 95%;
        margin: 20px auto 0 auto;
    }
`

const Signup = () => {

    const [memberUid, setMemberUid] = useState<String>('');
    const [memberPw, setMemberPw] = useState<String>('');
    const [memberPwChk, setMemberPwChk] = useState<String>('');
    const [memberName, setMemberName] = useState<String>('');
    const [memberCompany, setMemberCompany] = useState<String>('');
    const [memberPhone, setMemberPhone] = useState<String>('');
    const [memberEmail, setMemberEmail] = useState<String>('');

    const [authStep, setAuthStep] = useState(0);

    const [isAgree, setIsAgree] = useState(false);
    const [idClear, setIdClear] = useState(false);
    const [pwClear, setPwClear] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordChk, setShowPasswordChk] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPasswordChk = () => setShowPasswordChk((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const handleMouseDownPasswordChk = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const navigate = useNavigate();

    let secDisable = true;

    useEffect(() => {
        if(idClear && pwClear) {
            secDisable = false;
        }
    }, [idClear, pwClear])

    useEffect(() => {
        if(memberPwChk === memberPw && memberPw !== '') {
            setPwClear(true);
        }
    }, [memberPwChk])

    const idCheckHandler = async () => {
        const id = memberUid;
        const duplicateResponse = await duplicateApi(id);

        if(duplicateResponse) {
            alert('중복된 아이디입니다.');
            setIdClear(false);
            return;
        }
        setIdClear(true);
    }

    const signUpHandler = async () => {

        if(memberName.length < 1) {
            alert("이름을 입력해주세요");
            return;
        }
        if(memberPhone.length < 1) {
            alert("휴대폰 번호를 입력해주세요");
            return;
        }
        if(memberEmail.length < 1) {
            alert("이메일을 입력해주세요");
            return;
        }

        const data = {
            memberUid,
            memberPw,
            memberName,
            memberCompany,
            memberRole: 0,
            memberPhone,
            memberEmail
        }

        const signUpResponse = await signUpApi(data);

        if(!signUpResponse || !signUpResponse.result) {
            alert('회원가입에 실패했습니다.');
            return;
        }
        alert('회원가입에 성공했습니다.');
        navigate('/');
    }

    const viewTabs = () => {
        if(authStep === 1) {
            return (
                <IdPwWrapper>
                    <p className="t-info">회원 정보 입력</p>
                    <div className="id-area">
                        <div className="input-boxes">
                            <TextField
                                label="아이디"
                                id="registerId"
                                sx={{ m: 1, width: '100%' }}
                                variant="filled"
                                onChange={(e) => setMemberUid(e.currentTarget.value)}
                                required
                            />
                        </div>
                        <Button variant="contained" onClick={() => idCheckHandler()}>중복 확인</Button>
                    </div>
                    <div className="err-area">
                        {idClear ? <p className="success">사용 가능한 아이디입니다.</p> : <p className="err">아이디를 다시 확인해주세요.</p>}
                    </div>
                    <div className="pw-area">
                        <div className="input-boxes">
                            <FormControl sx={{ m: 1, width: '100%' }} variant="filled" required>
                                <InputLabel htmlFor="registerPw">비밀번호</InputLabel>
                                <FilledInput
                                    id="registerPw"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(e) => setMemberPw(e.currentTarget.value)}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className="input-boxes">
                            <FormControl sx={{ m: 1, width: '100%' }} variant="filled" required>
                                <InputLabel htmlFor="pwCheck">비밀번호 확인</InputLabel>
                                <FilledInput
                                    id="pwCheck"
                                    type={showPasswordChk ? 'text' : 'password'}
                                    onChange={(e) => setMemberPwChk(e.currentTarget.value)}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPasswordChk}
                                        onMouseDown={handleMouseDownPasswordChk}
                                        >
                                        {showPasswordChk ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className="err-area">
                        {pwClear ? <p className="success">비밀번호가 일치합니다.</p> : <p className="err">비밀번호가 일치하지 않습니다.</p>}
                    </div>

                    <div className="btnBox">
                        <Button 
                            fullWidth 
                            variant="contained" 
                            disabled={
                                idClear && pwClear ? false : true
                            } 
                            onClick={() => setAuthStep(authStep + 1)}>
                            다음
                        </Button>
                    </div>
                </IdPwWrapper>
            );
        }
        else if (authStep === 2) {
            return (
            <InfoWrapper>
                <p className="t-name">이름</p>
                <div className="input-boxes">
                    <TextField
                        label="이름을 입력해주세요."
                        id="name"
                        sx={{ m: 1, width: '100%' }}
                        variant="filled"
                        onChange={(e) => setMemberName(e.currentTarget.value)}
                        required
                    />
                </div>
                <p className="t-company">기업명</p>
                <div className="input-boxes">
                    <TextField
                        label="소속 기업 이름을 입력해주세요."
                        id="companyName"
                        sx={{ m: 1, width: '100%' }}
                        variant="filled"
                        onChange={(e) => setMemberCompany(e.currentTarget.value)}
                    />
                </div>
                <p className="t-phone">연락처</p>
                <div className="input-boxes">
                    <TextField
                        label="휴대폰 번호"
                        id="phone"
                        type="phone"
                        sx={{ m: 1, width: '100%' }}
                        variant="filled"
                        onChange={(e) => setMemberPhone(e.currentTarget.value)}
                        required
                    />
                    <TextField
                        label="이메일"
                        id="email"
                        type="email"
                        sx={{ m: 1, width: '100%' }}
                        variant="filled"
                        onChange={(e) => setMemberEmail(e.currentTarget.value)}
                        required
                    />
                </div>

                <div className="btnBox">
                    <Button fullWidth onClick={() => signUpHandler()} variant="contained">완료</Button>
                </div>
            </InfoWrapper>
            );
        }

        return (
            <PolicyWrapper>
                <p className="t-policy">개인정보 처리방침</p>
                <div className="policy-box">
                    <dl id="policyBox">
                        <dt>1. 개인정보의 처리 목적</dt>
                        <dd>
                            <h3>
                                {'<'}주식회사 에브림{'>'}('도메인주소'이하 'EVERYM')은(는) 다음의 목적을 위하여 개인정보를 처리하고
                                    있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.
                            </h3>
                        </dd>
                        <dd>
                            - 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급.배송 등
                        </dd>

                        <dt>2. 개인정보처리 위탁</dt>
                        <dd>
                            ①{'<'}주식회사 에브림{'>'}('EVERYM')은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
                        </dd>
                        <dd>
                            ②{'<'}주식회사 에브림{'>'}('도메인주소'이하 'EVERYM')은(는) 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
                        </dd>
                        <dd>
                            ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
                        </dd>

                        <dt>3. 정보주체의 권리</dt>
                        <dd>
                            <h3>
                                정보주체는 {'<'}주식회사 에브림{'>'}('도메인주소'이하 'EVERYM')에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
                            </h3>
                        </dd>
                        <dd>① 개인정보 열람요구</dd>
                        <dd>② 오류 등이 있을 경우 정정 요구</dd>
                        <dd>③ 삭제요구</dd>
                        <dd>④ 처리정지 요구</dd>

                        <dt>4. 처리하는 개인정보의 항목 작성</dt>
                        <dd>
                            <h3>
                                {'<'}주식회사 에브림{'>'}('도메인주소'이하 'EVERYM')은(는) 다음의 개인정보 항목을 처리하고 있습니다.
                            </h3>
                        </dd>
                        <dd>
                            <h3>① 필수항목</h3>
                        </dd>
                        <dd>
                            자택주소, 비밀번호 질문과 답, 비밀번호, 생년월일, 자택전화번호, 성별, 로그인ID, 휴대전화번호, 이름, 이메일, 회사명, 직책,
                            회사전화번호, 직업, 부서, 결제기록, 접속 IP 정보, 쿠키, 서비스 이용 기록, 접속 로그
                        </dd>
                        <dd style={marginTop}>
                            <h3>② 선택항목</h3>
                        </dd>
                        <dd>
                            자택주소, 비밀번호 질문과 답, 비밀번호, 생년월일, 자택전화번호, 성별, 로그인ID, 휴대전화번호, 이름, 이메일, 회사명, 직책, 회사전화번호, 직업, 부서, 기념일, 취미, 결혼여부, 신체정보, 학력, 주민등록번호, 종교, 신용카드정보, 은행계좌정보, 결제기록, 접속 IP 정보, 쿠키, 서비스 이용 기록, 접속 로그, 법정대리인 휴대전화번호, 법정대리인 자택 주소, 법정대리인 자택 전화번호, 법정대리인 이름
                        </dd>

                        <dt>5. 개인정보의 파기</dt>
                        <dd>
                            <h3>① 파기절차</h3>
                        </dd>
                        <dd>
                            이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
                        </dd>
                        <dd style={marginTop}>
                            <h3>② 파기기한</h3>
                        </dd>
                        <dd>
                            이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
                        </dd>
                        <dt>6. 개인정보의 안전성 확보 조치</dt>
                        <dd>
                            <h3>① 개인정보 취급 직원의 최소화 및 교육</h3>
                        </dd>
                        <dd>
                            개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
                        </dd>
                        <dd style={marginTop}>
                            <h3>② 개인정보의 암호화</h3>
                        </dd>
                        <dd>
                            이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
                        </dd>
                        <dt>7. 개인정보 보호책임자 작성</dt>
                        <dd>
                            <h3>
                                {'<'}주식회사 에브림{'>'}('도메인주소'이하 'EVERYM')은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                            </h3>
                        </dd>
                        <dd style={marginTop}>
                            <h3>① 개인정보 보호책임자</h3>
                        </dd>
                        <dd>
                            <table className="tb1">
                                <tbody>
                                    <tr>
                                        <th>성명</th>
                                        <td>김법성</td>
                                    </tr>
                                    <tr>
                                        <th>연락처</th>
                                        <td>010-6256-4398</td>
                                    </tr>
                                    <tr>
                                        <th>이메일</th>
                                        <td>everyminc@gmail.com</td>
                                    </tr>
                                </tbody>
                            </table>
                        </dd>
                        <dd style={marginTop}>
                            <h3>② 개인정보 보호 담당부서</h3>
                        </dd>
                        <dd>
                            <table className="tb1">
                                <tbody>
                                    <tr>
                                        <th>부서명</th>
                                        <td>서비스운영팀</td>
                                    </tr>
                                    <tr>
                                        <th>담당자</th>
                                        <td>이찬호</td>
                                    </tr>
                                    <tr>
                                        <th>연락처</th>
                                        <td>010-6880-4287</td>
                                    </tr>
                                    <tr>
                                        <th>이메일</th>
                                        <td>ch02.everym@gmail.com</td>
                                    </tr>
                                </tbody>
                            </table>
                        </dd>
                        <dt>8. 개인정보 처리방침 변경</dt>
                        <dd>
                            이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
                        </dd>
                    </dl>
                </div>
                <input type="radio" name="agree" id="agree" onChange={() => setIsAgree(true)} required/>
                <label htmlFor="agree">
                    <div className={isAgree ? "agreeChkBox checked" : "agreeChkBox"}>
                        <img src={process.env.PUBLIC_URL + "/imgs/02_SignUp_SignIn/Icn_Check.png"} alt="체크박스 체크" />
                    </div>
                    <span>위 내용에 동의합니다.</span>
                </label>

                <div className="btnBox">
                    <Button 
                        fullWidth
                        variant="contained"
                        disabled={
                            isAgree ? false : true
                        }
                        onClick={() => setAuthStep(authStep + 1)}>
                        다음
                    </Button>
                </div>
            </PolicyWrapper>
        );
    }

    return (
    <SignUpWrapper>
        {/* <form onSubmit={() => signUpHandler()}> */}
            {viewTabs()}
        {/* </form> */}
    </SignUpWrapper>

        );

}

export default Signup;