import {useState, useEffect} from 'react';
import axios from "axios";
import { useLocation } from "react-router-dom";

export type props = {
    postId: number,
    postTitle: string,
    postImages: string,
    postContents: string,
    postUploadDate: string,
  }

const Detail = () => {
    const location = useLocation();
    const postId = getPostId(location);

    const [article, setArticle] = useState<props>();

    useEffect(() => {

        axios.get("https://everyminc.com/api/posts/read", {
            params: {
                postId: postId,
            }
        })
        .then((res) => setArticle(res.data.post))
        .catch((err) => console.log(err)
        );

    }, []);

    return (
        <main className="main">
            <div className="sections">
                <div className="detailTitleBox">
                    <p className="detailTitle">{article?.postTitle}</p>
                    <p className="detailDate">{article?.postUploadDate}</p>
                </div>
                <hr />
                <div className="detailContentsBox">
                    {article?.postContents}
                </div>
            </div>
        </main>
    )
    

}

function getPostId(location: any) {
    let searchString = location.search;
    const params = new URLSearchParams(searchString);
    const postId = params.get('postId');
    return postId;
}

export default Detail;