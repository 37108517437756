import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Auth from './views/Auth';
import Main from './views/Main';
import Header from './components/Common/Header/Header';
import Contact from './views/Contact';
import News from './views/News/News';
import Detail from './views/News/Detail';
import { useCookies } from 'react-cookie';
import Consulting from './views/Consulting';
import MyPage from './views/MyPage';
import Service from './views/Service';
import Footer from './components/Common/Footer/Footer';

function App() {

  const [cookies] = useCookies();

  useEffect(() => {
    if(!cookies.token) {
      localStorage.removeItem('member-storage');
    }
  }, [cookies])
  
  return (
    <>
      <Header />

      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/auth' element={<Auth />} />
        <Route path='/service' element={<Service />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/news' element={<News />} />
        <Route path='/news/read' element={<Detail />} />
        <Route path='/consulting' element={cookies.token ? <Consulting /> : <Auth />} />
        <Route path='/mypage' element={cookies.token ? <MyPage /> : <Auth />} />
        <Route path='/mypage/*' element={cookies.token ? <MyPage /> : <Auth />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
