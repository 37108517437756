import { useState } from "react";
import Login from "../components/Auth/Login";
import Signup from "../components/Auth/Signup";
import { styled } from "styled-components";

const MainContainer = styled.div`
    width: 625px;
    height: 600px;
    margin: auto;

    .menuContainer {
        color: white;
        display: flex;
        text-align: center;
        font-family: NotoSansRegular;
    }
    .menuContainer div {
        width: 50%;
        padding: 10px 0;
        cursor: pointer;
        transition: all 0.2s ease;
        opacity: 0.5;
        border-bottom: 2px solid white;
    }
    .menuContainer div:hover {
        opacity: 1;
    }
    .focused {
        opacity: 1 !important;
    }
    
    .tabContainer {
        display: flex;
        width: 80%;
        height: 430px;
        padding: 10px;
        margin: auto;
        margin-top: 70px;
        background-color: white;
        box-shadow: 0px -6px 20px #0D0A191A;
        border-radius: 12px;
    }
`

const Auth = () => {

    const [currentTab, setCurrentTab] = useState(0);

    const onMenuClick = (num: number) => {
        setCurrentTab(num);
    }

    return (
        // 탭에 따라 달라지는 login, signup 화면
        <main className="main">
            <div className="sections loginSection">
                <div className="bg">
                    <img src={process.env.PUBLIC_URL + "/imgs/02_SignUp_SignIn/Img_Background_.png"} alt="auth 배경" />
                </div>
                <MainContainer>
                    <div className="menuContainer">
                        <div className={currentTab === 0 ? "loginMenu focused" : "loginMenu"} onClick={() => onMenuClick(0)}><span>로그인</span></div>
                        <div className={currentTab === 1 ? "signUpMenu focused" : "signUpMenu"} onClick={() => onMenuClick(1)}><span>회원가입</span></div>
                    </div>
                    <div className="tabContainer">
                        {currentTab == 0 ? <Login /> : <Signup />}
                    </div>
                </MainContainer>
            </div>
        </main>
    );

}

export default Auth;