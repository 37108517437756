import axios from 'axios';

export const createCsApi = async (data: any) => {

    const response = await axios.post("https://everyminc.com/api/cs/apply", data).catch((err) => null);
    if(!response) return null;

    const result = response.data;
    
    return result;

}