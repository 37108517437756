import styled from 'styled-components';
import { useStore } from '../../stores';
import MenuItems from './MenuItems';
import LogoutIcon from '@mui/icons-material/Logout';
import { SvgIcon } from '@mui/material';
import { useCookies } from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';

const Container = styled.div`

    width: 250px;
    height: 100%;
    border: 1px solid #E6EAEE;
    border-radius: 20px;
    background-color: white;
    font-family: NotoSansRegular;

    .accountInfoBox {
        width: 100%;
        height: 80px;
        border-radius: 20px 20px 0 0;
        background-color: #2F75EC;
        color: white;
    }
    .accountInfoBox p {
        margin: 0;
        padding: 10px 0 0 20px;
    }
    .member {
        font-family: NotoSansBold;
    }

    a {
        text-decoration: none;
    }
`

const LogoutBox = styled.div`

    border-radius: 0 0 20px 20px;
    margin: 10px 0;
    cursor: pointer;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    opacity: 0.75;
    transition: all 0.2s ease;

    svg {
        color: #757B8C;
        padding-left: 20px;
    }
    span {
        height: 24px;
        color: #757B8C;
        margin-left: 10px;
        font-family: NotoSansBold;
        font-size: 15px;
    }
    &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.1);
    }
`

type functionProps = {
    paramHandler: Function;
}

export default function Menu() {

    const [cookies, setCookies] = useCookies();
    const {member, removeMember} = useStore();

    const navigate = useNavigate();

    const logOutHandler = () => {

        if(window.confirm("로그아웃하시겠습니까?")) {
            setCookies('token', '', {expires: new Date()});
            removeMember();
            return navigate('/');
        }

        return;
    }

  return (
    <Container>
        <div className="accountInfoBox">
            {member && <p className="name"><span className='member'>{member.memberName}</span> 님</p>}
            {member && <p className="email"><span className='member'>{member.memberEmail}</span></p>}
        </div>
        <MenuItems />
        <LogoutBox onClick={() => logOutHandler()}>
            <SvgIcon component={LogoutIcon}/>
            <span className="menuDesc">로그아웃</span>
        </LogoutBox>
    </Container>
  )
}
