import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface MemberStore {
    member: any;
    setMember: (member: any) => void;
    removeMember: () => void;
}

const useStore = create(
    persist<MemberStore>(
        (set) => ({
            member: null,
            setMember: (member: any) => {
                set((state) => ({...state, member}));
            },
            removeMember: () => {
                set((state) => ({...state, member: null}));
            },
        }),
        {
            name: 'member-storage',
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useStore;