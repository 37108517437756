import {Link, useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { FilledInput, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useCookies } from 'react-cookie';
import { useStore } from '../../stores';
import { signInApi } from '../../apis/Auth';
import styled from 'styled-components';

const LoginWrapper = styled.div`
    width: 100%;
    padding: 20px;
    font-family: NotoSansRegular;

    label {
        font-family: NotoSansRegular;
    }

    .input-boxes {
        width: 90%;
        margin: 10px auto 30px auto;
    }
    .MuiFormControl-root {
        margin: 0;
    }
    .MuiInputBase-root {
        background: none;
        border: 1px solid #E4E6E8;
    }
    .MuiInputBase-root:hover {
        background: none;
    }

    .auto-login-box {
        display: none;
    }

    .login-btn-box {
        width: 90%;
        height: 50px;
        margin: auto;
    }
    .login-btn-box button {
        height: 100%;
        font-family: NotoSansRegular;
    }
`

const Login = () => {

    const [memberUid, setMemberUid] = useState<String>('');
    const [memberPw, setMemberPw] = useState<String>('');
    const [requestResult, setRequestResult] = useState<String>('');

    const navigate = useNavigate();

    const [cookies, setCookies] = useCookies();

    const {member, setMember} = useStore();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const signInHandler = async () => {

        if(memberUid.length < 1 || memberPw.length < 1) {
            alert('아이디와 비밀번호를 입력하세요.');
            return;
        }

        const data = {
            memberUid,
            memberPw,
        }

        const signInResponse = await signInApi(data);

        if(!signInResponse) {
            alert("로그인에 실패했습니다.");
            return;
        }

        if(!signInResponse.result) {
            alert("로그인에 실패했습니다.");
            return;
        }
        const { token, exprTime, member } = signInResponse.data;
        const expr = new Date().getTime() + exprTime;
        const expires = new Date(expr);
        
        setCookies('token', token, { expires });
        setMember(member);

        navigate('/');

    }


    return (
            <LoginWrapper>
                <div className="input-boxes">
                    <TextField
                        label="아이디"
                        id="registerId"
                        sx={{ m: 1, width: '100%' }}
                        variant="filled"
                        onChange={(e) => setMemberUid(e.currentTarget.value)}
                        required
                        />
                </div>
                <div className="input-boxes">
                    <FormControl sx={{ m: 1, width: '100%' }} variant="filled" required>
                        <InputLabel htmlFor="registerPw">비밀번호</InputLabel>
                        <FilledInput
                            id="registerPw"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => setMemberPw(e.currentTarget.value)}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
                <div className="auto-login-box">
                    <label htmlFor="loginAuto">
                        <input type="checkbox" name="auto" id="loginAuto" />
                        자동 로그인하기
                    </label>
                    <Link to='/' className='find-pw'>비밀번호를 잊으셨나요?</Link>
                </div>
                <div className="login-btn-box">
                    <Button fullWidth onClick={() => signInHandler()} variant="contained">로그인</Button>
                </div>
            </LoginWrapper>
    );

}

export default Login;