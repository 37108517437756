import {Link} from 'react-router-dom';
import styled, {keyframes} from 'styled-components';

const scrollMove = keyframes`
    0% {
        bottom: 30px;
    }
    100% {
        bottom: 20px;
    }
`

const HomeDescBox = styled.div`
    position: absolute;
    top: 265px;
    left: 135px;
    width: 469px;
    height: 135px;
    color: white;
    font-family: NotoSansBold;
    font-size: 50px;
`

const ButtonArea = styled.div`
    position: absolute;
    display: flex;
    top: 490px;
    left: 130px;
    width: 417px;
    height: 85px;
    padding: 5px;
    background-color: rgba(47, 117, 236, 0.7);
    border-radius: 10px;
    align-items: center;
`

const ButtonDesc = styled.div`
    width: 60%;
    color: white;
    padding-left: 10px;
    font-family: NotoSansBold;
    font-size: 15px;
`

const StyledButton = styled.button`
    width: 35%;
    height: 46px;
    background-color: white;
    border: none;
    border-radius: 10px;
    opacity: 1;
    cursor: pointer;
    transition: all 0.2s ease;
    
    > a {
        color: #1E58BB;
        transition: all 0.2s ease;
    }

    &:hover > a {
        color: #2F75EC;
    }

    span {
        padding-right: 7px;
        transition: all 0.2s ease;
    }
    &:hover span {
        padding-right: 12px;
    }
`

const ScrollArea = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 34px;
    color: white;
    animation: ${scrollMove} 1s infinite;
`

const HomeMain = () => {

    return (
        <section className="sections">
            <div className="bg">
                <img src={process.env.PUBLIC_URL + "/imgs/01_Main_Home/Img_Background_.png"} alt="홈 메인 bg" id='homeBg' />
            </div>
            <HomeDescBox>
                <p className="homeDesc">경력과 혁신이 만나</p>
                <p className="homeDesc">성공을 만드는 서비스</p>
            </HomeDescBox>
            <ButtonArea>
                <ButtonDesc>
                    고경력 금형사출 전문가들에게
                    <br/>
                    컨설팅을 받아보세요.
                </ButtonDesc>
                <StyledButton>
                    <Link to='/consulting' className='buttonDesc'>
                        <span>&gt;</span>
                        스마트 컨설팅
                    </Link>
                </StyledButton>
            </ButtonArea>
            <ScrollArea>
                <span id='scroll'>scroll</span>
            </ScrollArea>
        </section>
    );

}

export default HomeMain;