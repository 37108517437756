import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from 'react-slick';
import styled from "styled-components";

const StyledSlider = styled(Slider)`

    > button {
        width: 80px;
        z-index: 9;
    }
    > button::before {
        color: #2F75EC;
        background-color: #EBF1FF;
        padding: 7px 12px;
        border-radius: 5px;
        border: 1px solid #2F75EC;
        transition: all 0.2s ease;
        opacity: 0.9;
    }
    .slick-prev.slick-disabled:hover::before,
    .slick-next.slick-disabled:hover::before{
        cursor: default;
        color: #2F75EC;
        background-color: #EBF1FF;
    }
    .slick-prev {
        left: 21%;
        top: 80%;
    }
    .slick-prev::before {
        font-family: NotoSansBold;
        font-size: 15px;
        content: '< PREV';
    }
    .slick-next {
        left: 28%;
        top: 80%;
    }
    .slick-next::before {
        font-family: NotoSansBold;
        font-size: 15px;
        content: 'NEXT >';
    }
    > button:hover::before {
        color: #EBF1FF;
        background-color: #2F75EC;
    }
    .slick-dots li button:before {
        transition: all 0.2s ease;
    }

    .slick-dots li button:hover:before {
        opacity: 1;
        color: #e5e5e5;
    }
`

const StepBox = styled.div`
    width: 100%;
    height: 500px;
    display: flex !important;

    .leftBox {
        display: flex;
        width: 50%;
        justify-content: center;
    }

    .contentsStepBox {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .contentsStepBox img {
        width: 40px;
        margin-right: 10px;
    }

    .contentsBox {
        display: flex;
        width: 310px;
        flex-direction: column;
        justify-content: center;
    }

    .contentsTitle {
        font-family: NotoSansBlack;
        font-size: 40px;
    }
    .contentsDesc {
        font-family: NotoSansRegular;
        font-size: 25px;
        margin: 10px 0;
    }
    .contentsDesc:last-child {
        margin-bottom: 100px;
    }

    .stepImgBox {
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .stepImgBox img {
        height: 100%;
    }
`

const IconBox = styled.div`
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 70%;
    }
`


const Manual = () => {

    const carousel_settings = {
        dots: false,
        infinite: false,
        speed: 500,
        fade: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
    };

    return (
        <section className="sections">
            <StyledSlider {...carousel_settings}>
                <StepBox>
                    <div className="leftBox">
                        <IconBox>
                            <img src={process.env.PUBLIC_URL + "/imgs/04_About/Icn_Step1.png"} alt="step icon" />
                        </IconBox>
                        <div className="contentsBox">
                            <div className="contentsStepBox">
                                <img src={process.env.PUBLIC_URL + "/imgs/04_About/Icn_Step_Line.png"} alt="step line" />
                                <p className="contentsStep">Step1</p>
                            </div>
                            <div className="contentsTitleBox">
                                <p className="contentsTitle">컨설팅 종류 결정</p>
                            </div>
                            <div className="contentsDescBox">
                                <p className="contentsDesc">서면 컨설팅과 대면 컨설팅 중,</p>
                                <p className="contentsDesc">원하는 종류를 선택합니다.</p>
                            </div>
                        </div>
                    </div>
                    <div className="stepImgBox">
                        <img src={process.env.PUBLIC_URL + "/imgs/04_About/Img_Step1.png"} alt="stepImg" />
                    </div>
                </StepBox>
                <StepBox>
                    <div className="leftBox">
                        <IconBox>
                            <img src={process.env.PUBLIC_URL + "/imgs/04_About/Icn_Step2.png"} alt="step icon" />
                        </IconBox>
                        <div className="contentsBox">
                            <div className="contentsStepBox">
                                <img src={process.env.PUBLIC_URL + "/imgs/04_About/Icn_Step_Line.png"} alt="step line" />
                                <p className="contentsStep">Step2</p>
                            </div>
                            <div className="contentsTitleBox">
                                <p className="contentsTitle">컨설팅 신청</p>
                            </div>
                            <div className="contentsDescBox">
                                <p className="contentsDesc">주어진 양식에 맞추어,</p>
                                <p className="contentsDesc">컨설팅 신청서를 작성합니다.</p>
                            </div>
                        </div>
                    </div>
                    <div className="stepImgBox">
                        <img src={process.env.PUBLIC_URL + "/imgs/04_About/Img_Step2.png"} alt="stepImg" />
                    </div>
                </StepBox>
                <StepBox>
                    <div className="leftBox">
                        <IconBox>
                            <img src={process.env.PUBLIC_URL + "/imgs/04_About/Icn_Step3.png"} alt="step icon" />
                        </IconBox>
                        <div className="contentsBox">
                            <div className="contentsStepBox">
                                <img src={process.env.PUBLIC_URL + "/imgs/04_About/Icn_Step_Line.png"} alt="step line" />
                                <p className="contentsStep">Step3</p>
                            </div>
                            <div className="contentsTitleBox">
                                <p className="contentsTitle">전문가 매칭</p>
                            </div>
                            <div className="contentsDescBox">
                                <p className="contentsDesc">신청서를 바탕으로,</p>
                                <p className="contentsDesc">엄선된 전문가가 매칭됩니다.</p>
                            </div>
                        </div>
                    </div>
                    <div className="stepImgBox">
                        <img src={process.env.PUBLIC_URL + "/imgs/04_About/Img_Step3.png"} alt="stepImg" />
                    </div>
                </StepBox>
                <StepBox>
                    <div className="leftBox">
                        <IconBox>
                            <img src={process.env.PUBLIC_URL + "/imgs/04_About/Icn_Step4.png"} alt="step icon" />
                        </IconBox>
                        <div className="contentsBox">
                            <div className="contentsStepBox">
                                <img src={process.env.PUBLIC_URL + "/imgs/04_About/Icn_Step_Line.png"} alt="step line" />
                                <p className="contentsStep">Step4</p>
                            </div>
                            <div className="contentsTitleBox">
                                <p className="contentsTitle">컨설팅 진행</p>
                            </div>
                            <div className="contentsDescBox">
                                <p className="contentsDesc">매칭된 전문가와</p>
                                <p className="contentsDesc">컨설팅을 진행합니다.</p>
                            </div>
                        </div>
                    </div>
                    <div className="stepImgBox">
                        <img src={process.env.PUBLIC_URL + "/imgs/04_About/Img_Step4.png"} alt="stepImg" />
                    </div>
                </StepBox>
            </StyledSlider>
        </section>
    );

}

export default Manual;