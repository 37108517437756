import styled, {keyframes} from "styled-components";


const scrollMove = keyframes`
    0% {
        bottom: 30px;
    }
    100% {
        bottom: 20px;
    }
`

const HomeDescBox = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 202px;
    color: white;
    font-family: NotoSansBold;
    font-size: 70px;
    transform: translate(0, -50%);
    text-align: center;

    p {
        margin: 0;
    }
`

const ScrollArea = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 30px;
    left: 0;
    width: 100%;
    height: 34px;
    color: white;
    animation: ${scrollMove} 1s infinite;
`

const Home = () => {

    return (
        <section className="sections">
            <div className="bg">
                <img src={process.env.PUBLIC_URL + "/imgs/04_About/Img_Background_.png"} alt="홈 메인 bg" />
            </div>
            <HomeDescBox>
                <p className="homeDesc">경력과 혁신이 만나</p>
                <p className="homeDesc">성공을 만드는 서비스</p>
            </HomeDescBox>
            <ScrollArea>
                <span id='scroll'>scroll</span>
            </ScrollArea>
        </section>
    );

}

export default Home;