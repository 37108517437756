import { useState } from "react";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import MessageIcon from '@mui/icons-material/Message';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { styled } from "styled-components";
import { SvgIcon } from "@mui/material";
import { NavLink } from "react-router-dom";

const MenuContainer = styled.div `
    width: 100%;
    height: 480px;
    display: flex;
`

const MenuItemContainer = styled.div`

    width: 100%;

    .menuTitleBox {
        margin-top: 50px;
        margin-left: 20px;
        font-family: NotoSansBold;
    }

    p {
        margin: 0;
    }
`

const MenuItemWrapper = styled.div`

    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 0 20px;
    
    a {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        opacity: 0.75;
        transition: all 0.2s ease;
    }
    svg {
        color: #2F75EC;
    }
    span {
        height: 24px;
        color: #757B8C;
        margin-left: 10px;
        font-size: 15px;
    }
    a:hover {
        opacity: 1;
    }
`

const MenuItems = () => {

    const accountMenuItems = [
        {
            id: "info",
            path: "/mypage/info",
            desc: "내 정보",
            icon: VerifiedUserIcon
        },
        {
            id: "message",
            path: "/mypage/message",
            desc: "메세지",
            icon: MessageIcon
        },
    ]

    const projectMenuItem = 
        {
            id: "project",
            path: "/mypage/project",
            desc: "프로젝트",
            icon: AccountTreeIcon
        }
    

    return (
        <MenuContainer>
            <MenuItemContainer>
                <div className="menuTitleBox">
                    <p className="menuTitle">ACCOUNT</p>
                </div>
                {accountMenuItems.map((item) => (
                    <MenuItemWrapper key={item.id}>
                        <NavLink to={item.path}>
                            <SvgIcon component={item.icon}/>
                            <span className="menuDesc">{item.desc}</span>
                        </NavLink>
                    </MenuItemWrapper>
                ))}

                <div className="menuTitleBox">
                    <p className="menuTitle">PROJECT</p>
                </div>
                <MenuItemWrapper>
                    <NavLink to={projectMenuItem.path}>
                        <SvgIcon component={projectMenuItem.icon}/>
                        <span className="menuDesc">{projectMenuItem.desc}</span>
                    </NavLink>
                </MenuItemWrapper>
            </MenuItemContainer>
        </MenuContainer>
    )


}

export default MenuItems;