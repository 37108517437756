import React from 'react'
import Home from '../components/Service/Home';
import Information from '../components/Service/Information';
import Manual from '../components/Service/Manual';

export default function Service() {
  return (
    <main className="main">
      <Home />
      <Information />
      <Manual />
    </main>
  )
}
