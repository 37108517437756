import { createGlobalStyle } from "styled-components";
import NotoSansBlack from '../../public/fonts/Noto_Sans_KR/NotoSansKR-Black.otf';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'MontSerratBlack';
        src: url({process.env.PUBLIC_URL + "/fonts/Montserrat/Montserrat-Black.ttf"});
    }
    @font-face {
        font-family: 'MontSerratExBold';
        font-style: normal;
        font-weight: 800;
        src: url({process.env.PUBLIC_URL + "/fonts/Montserrat/Montserrat-ExtraBold.ttf"}) format('truetype');
    }
    @font-face {
        font-family: MontSerratBold;
        src: url({process.env.PUBLIC_URL + "/fonts/Montserrat/Montserrat-Bold.ttf"});
    }
    @font-face {
        font-family: MontSerratRegular;
        src: url({process.env.PUBLIC_URL + "/fonts/Montserrat/Montserrat-Regular.ttf"});
    }
    

    @font-face {
        font-family: PretendardBlack;
        src: url({process.env.PUBLIC_URL + "/fonts/Pretendard/Pretendard-Black.woff"});
    }
    @font-face {
        font-family: PretendardBold;
        src: url({process.env.PUBLIC_URL + "/fonts/Pretendard/Pretendard-Bold.woff"});
    }
    @font-face {
        font-family: PretendardRegular;
        src: url({process.env.PUBLIC_URL + "/fonts/Pretendard/Pretendard-Regular.woff"});
    }


    @font-face {
        font-family: NotoSansBold;
        src: url({process.env.PUBLIC_URL + "/fonts/Noto_Sans_KR/NotoSansKR-Bold.otf"}) format('opentype');
        font-weight: 900;
        font-display: swap;
    }
    @font-face {
        font-family: NotoSansRegular;
        src: url({process.env.PUBLIC_URL + "/fonts/Noto_Sans_KR/NotoSansKR-Regular.otf"});
    }
    @font-face {
        font-family: NotoSansBlack;
        src: url({process.env.PUBLIC_URL + "/fonts/Noto_Sans_KR/NotoSansKR-Black.otf"});
    }
`

export default GlobalStyle;