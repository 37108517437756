import { styled } from "styled-components"
import { useStore } from "../../stores"
import { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";

const InfoSection = styled.div`

  width: 100%;
  height: 80%;
  
  .infoTitleBox {
    padding: 40px;
  }
  .infoTitle {
    margin: 0;
    font-family: NotoSansBold;
    font-size: 20px;
  }
  .infoBox {
    padding-left: 40px;
  }

  table {
    width: 100%;
    border-spacing: 0 20px;
  }
  th {
    text-align: left;
  }

  .information {
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .btnBox {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`


export default function MyInfo() {

  const {member} = useStore();

  const alertTxt = "정보를 불러올 수 없습니다.";

  const [memberUid, setMemberUid] = useState<string | null>(member?member.memberUid : null);
  const [memberName, setMemberName] = useState(alertTxt);
  const [memberCompany, setMemberCompany] = useState(alertTxt);
  const [memberPhone, setMemberPhone] = useState(alertTxt);
  const [memberEmail, setMemberEmail] = useState(alertTxt);

  useEffect(() => {
    if(member) {
      setMemberName(member.memberName);
      setMemberCompany(member.memberCompany);;
      setMemberPhone(member.memberPhone);
      setMemberEmail(member.memberEmail);
    }
  }, [])

  return (
    <InfoSection>
      <div className="infoTitleBox">
        <p className="infoTitle">내 정보</p>
      </div>
      <div className="infoBox">
        <table className="infoTable">
          <tbody>
            <tr>
              <th colSpan={1}><span>ID</span></th>
              <td colSpan={3}><span>{member?member.memberUid : "정보를 불러올 수 없습니다."}</span></td>
            </tr>
            <tr>
              <th><span>이름</span></th>
              <td>
                <TextField id="updateName" sx={{m:1, width:'80%'}} label=" 이름" variant="standard" name="updateName" onChange={(e) => setMemberName(e.currentTarget.value)} defaultValue={member?member.memberName : alertTxt} required/>
              </td>
              <th><span>기업명</span></th>
              <td>
                <TextField id="updateName" sx={{m:1, width:'80%'}} label=" 기업명" variant="standard" name="updateCompany" onChange={(e) => setMemberCompany(e.currentTarget.value)} defaultValue={member?member.memberCompany : alertTxt}/>
              </td>
            </tr>
            <tr>
            <th><span>연락처</span></th>
              <td>
                <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} id="updatePhone" sx={{m:1, width:'80%'}} label=" 휴대폰" variant="standard" name="updatePhone" onChange={(e) => setMemberPhone(e.currentTarget.value)} defaultValue={member?member.memberPhone : alertTxt} required/>
              </td>
              <th>
                <span>이메일</span>
              </th>
              <td>
                <TextField id="updateName" sx={{m:1, width:'80%'}} label=" 이메일" variant="standard" name="updateEmail" onChange={(e) => setMemberEmail(e.currentTarget.value)} defaultValue={member?member.memberEmail : alertTxt} required/>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="informationBox">
          <p className="information">수정할 정보를 입력한 후, 정보 수정 버튼을 눌러주세요.</p>
        </div>
        <div className="btnBox">
          <Button variant="contained">정보 수정</Button>
        </div>
      </div>
    </InfoSection>
  )
}
